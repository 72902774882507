
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import CustomerTable from "@/components/Customers/CustomerTable.vue";
import Card from "primevue/card";
import { FieldServiceObject } from "@/types/state/fieldServices";

export default defineComponent({
  name: "FieldServiceDataWrapper",
  components: {
    CustomerTable,
    Card,
  },
  computed: {
    ...mapGetters({
      getFieldServices: "fieldServices/getFieldServices",
      getRecentlyViewedCustomers: "customerInquiry/getRecentlyViewed",
    }),
  },
  data() {
    return {
      items: [] as Array<any>,
    };
  },
  methods: {
    ...mapActions({
      addNewFieldService: "fieldServices/addNewFieldService",
      addNotification: "notification/add",
            removeRecentlyViewedCustomer:
        "customerInquiry/removeRecentlyViewedCustomer",
    }),
    handleClick(e: any) {
      const newOrders = this.getFieldServices.filter((fso: FieldServiceObject) => fso.old_record.order_no && fso.old_record.order_no.startsWith("New"));
      const newOrderCount = newOrders.length + 1;
      this.addNewFieldService(`New-${newOrderCount}`)
      const cust_id = e.data.cust_id
      this.$router.push({path: `/fieldservices/New-${newOrderCount}`, query: {cust_id: cust_id} })
    },
    handleRemoveCustomer(custId: string) {
      this.removeRecentlyViewedCustomer(custId)
    }
  },
});
